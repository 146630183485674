import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';

function HomePage({ data }) {
  const articles = data.latestArticles.edges;

  return (
    <Layout>
      {!!articles.length && (
        <>
          <Label>Latest articles</Label>
          <ArticlesWrapper>
            {articles.map(({ node }) => (
              <Wrapper key={node.fields.slug} to={node.fields.slug}>
                <Title>{node.frontmatter.title}</Title>
                <Excerpt>{node.excerpt}</Excerpt>
                <Subtitle>{node.frontmatter.date}</Subtitle>
              </Wrapper>
            ))}
          </ArticlesWrapper>
          <ArticlesLink to="/articles">All Articles →</ArticlesLink>
        </>
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  query Index {
    latestArticles: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/content/" }
        fields: { draft: { ne: true } }
      }
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
          }
        }
      }
    }
  }
`;

const Excerpt = styled.div`
  color: var(--color-muted);
  padding: 1rem 0 0.5rem 0;
  line-height: 1.25rem;
`;

const Title = styled.h3`
  display: inline-block;
  font-size: 1.5rem;
  line-height: 2rem;
  padding-top: 1rem;
  font-family: var(--font-serif);
`;

const Subtitle = styled.div`
  color: var(--color-muted);
  font-size: 1rem;
  opacity: 0.5;
`;

const Wrapper = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: var(--color-accent);
    & .gatsby-image-wrapper {
      transform: translateY(-1px);
      box-shadow: 0 8px 25px var(--color-hover);
    }
  }
`;

const Banner = styled.section`
  display: flex;
  flex-direction: column;
  padding-bottom: 10rem;
`;

const Heading = styled.h1`
  font-family: var(--font-serif);
  font-size: 3rem;
  line-height: 3.5rem;

  @media (min-width: 768px) {
    font-size: 3.75rem;
    line-height: 4rem;
  }
`;

const SubHeading = styled.h2`
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 0.5rem;
  @media (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const Label = styled.h3`
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
`;

const ArticlesWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
`;

const ArticlesLink = styled(Link)`
  margin-top: 4rem;
  margin-bottom: 6rem;
  display: inline-block;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: inherit;
  text-decoration: none;
  &:hover {
    color: var(--color-accent);
  }
`;

export default HomePage;
